<template>
  <div class="container-container">
  <div class="container">
    <div class="row">
    <div class="col-md-6 mx-auto hud" v-if="isGameActive && this.geojson != null && this.geojson.features.length != 0">
      <span v-if="lang == 'en'">Where is</span>
      <span v-if="lang == 'ru'">Где находится</span>
      <h2>
        {{ this.regionToGuess }}
      </h2>
      <span v-if="this.geojson">
        <i class="bi bi-check2"></i> {{ this.rightAnswers }} <i class="bi bi-geo"></i> {{ this.geojson.features.length }}/{{ this.totalRegions }}
        <router-link v-if="lang == 'en'" to="/">End game</router-link>
        <router-link v-if="lang == 'ru'" to="/">Завершить игру</router-link>
      </span>
    </div>

    <div class="col-md-6 mx-auto hud" v-if="this.geojson != null && this.geojson.features.length == 0">
      <br>
      <h2 v-if="lang == 'en'">Yay! {{ this.rightAnswers }} correct answers.</h2>
      <h2 v-if="lang == 'ru'">Ура! {{ this.rightAnswers }} правильных ответов.</h2>

      <p v-if="lang == 'en'">+{{ this.rightAnswers }} points addedd</p>
      <p v-if="lang == 'ru'">+{{ this.rightAnswers }} очков</p>

      <router-link v-if="lang == 'en'" to="/">Main menu</router-link>
      <router-link v-if="lang == 'ru'" to="/">Вернутся в меню</router-link>
      <br>
    </div>

    <div class="col-md-6 mx-auto hud" v-if="!isGameActive">
      <br>

      <span v-if="lang == 'en'">
        <h2 v-if="isLastAnswerCorrect">
          🎉 Correct!
        </h2>
        <h2 v-if="!isLastAnswerCorrect">
          ❌ Wrong!
        </h2>
      </span>

      <span v-if="lang == 'ru'">
        <h2 v-if="isLastAnswerCorrect">
          🎉 Правильно!
        </h2>
        <h2 v-if="!isLastAnswerCorrect">
          ❌ Неправильно!
        </h2>
      </span>

      <br>
    </div>

    </div>
    </div>
  </div>
  <div id="map"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';

export default {
  name: 'App',
  data() {
    return {
      map: null,
      geojson: null,
      regionToGuess: null,
      hoveredPolygonId: null,
      geojsonWrongAnswers: [],
      geojsonCorrectAnswers: [],
      isGameActive: true,
      totalRegions: 0,
      rightAnswers: 0,
      isLastAnswerCorrect: false,
      baseZoom: 10,
      zoomZoom: 11,
      zoomBack: true,
      lang: 'ru',
      baseCenter: [37.614, 55.752],
      geojsonUrl: null,
      levelQuantity: null
    }
  },
  props: {
    gameString: String,
    level: Number
  },
  mounted(){
    this.getLang();
    this.loadGame();
  },
  methods: {
    // add methods here
    getLang(){
      this.lang = localStorage.getItem('lang')
      if(this.lang == null){
        this.lang = 'en'
      }
    },
    getCurrentRegion(){
      // get random element from geojson and retrieve its name
      if(this.geojson.features.length == 0){
        this.addScore(this.rightAnswers)
        return null;
      }
      this.regionToGuess = null
      const randomRegion = this.geojson.features[Math.floor(Math.random() * this.geojson.features.length)];
      
      if(this.lang == 'en' && randomRegion.properties['name:en'] != null){
        console.log(randomRegion.properties['name:en'])
        this.regionToGuess = randomRegion.properties['name:en'];
      }

      if(this.lang == 'ru' && randomRegion.properties['name:ru'] != null){
        console.log(randomRegion.properties['name:ru'])
        this.regionToGuess = randomRegion.properties['name:ru'];
      }

      console.log(this.regionToGuess)

      if(this.regionToGuess == null){
        console.log(randomRegion.properties.name)
        this.regionToGuess = randomRegion.properties.name;
      }

    },
    loadGame(){
      this.isGameActive = false

      // parse get params
      //const urlParams = new URLSearchParams(window.location.search);
      var game = this.gameString//urlParams.get('game');
      if(game == null){
        game = "moscow"
      }
      // get json
      fetch('../../games/' + game + '.json')
      .then(response => response.json())
      .then(data => {
        this.geojsonUrl = data.geojson;
        this.baseZoom = data.base_zoom;
        this.zoomZoom = data.zoom_to_point;
        this.baseCenter = data.center;
        this.zoomBack = data.zoom_back;

        document.title = data.name+" - borderquiz.com"
        if(this.level == 1){
          this.levelQuantity = data.level_1;
        } 
        if(this.level == 2){
          this.levelQuantity = data.level_2;
        }
        if(this.level == 3){
          this.levelQuantity = data.level_3;
        }
        if(this.level == 4){
          this.levelQuantity = data.level_4;
        }
        
        
        this.isGameActive = true;
        this.initMap();
        // add max zoom and min zoom later
        
      });

    },
    initMap(){
      mapboxgl.accessToken = 'pk.eyJ1IjoiYW5keWRvcmlhbiIsImEiOiJjam40ajdmc2MyOHY1M2twbGFydHl5MzlkIn0.qmBgiNmI8NFSArOC8cebsQ';
      this.map = new mapboxgl.Map({
        container: 'map', // container ID
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: 'mapbox://styles/andydorian/cljbraw4400ej01r21n05a2ae', // style URL
        center: this.baseCenter, // starting position
        zoom: this.baseZoom // starting zoom
      });
      // load geojson from file
      fetch(this.geojsonUrl)
        .then(response => response.json())
        .then(data => {


          this.geojson = data;

          console.log(this.geojson.features.length)
          
          this.geojson.features = this.geojson.features.sort(() => Math.random() - Math.random()).slice(0, this.levelQuantity);


          this.totalRegions = this.geojson.features.length;
      });

      this.map.on('click', (e) => {
        if (!this.isGameActive) {
          return;
        }
        // find polygon in geojson and remove it
        this.geojson.features.forEach((feature, index) => {
          if (feature.geometry != null && (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon')) {
            // check if click is inside polygon
            if (turf.booleanPointInPolygon(turf.point([e.lngLat.lng, e.lngLat.lat]), feature)) {
              this.isGameActive = false;
              this.isLastAnswerCorrect = true;
              if (feature.properties.name === this.regionToGuess || feature.properties['name:en'] === this.regionToGuess || feature.properties['name:ru'] === this.regionToGuess) {
                
                this.geojsonCorrectAnswers.push(feature);
                this.map.getSource('rf-regions-correct').setData({
                  'type': 'FeatureCollection',
                  'features': this.geojsonCorrectAnswers
                });
                
                this.map.flyTo({
                  center: turf.centerOfMass(feature).geometry.coordinates,
                  zoom: this.zoomZoom
                });

                // wait for 5 seconds
                setTimeout(() => {

                  this.geojson.features.splice(index, 1);
                  this.geojsonCorrectAnswers = [];
                  // update map source
                  this.map.getSource('rf-regions').setData(this.geojson);
                  this.map.getSource('rf-regions-correct').setData({
                    'type': 'FeatureCollection',
                    'features': this.geojsonCorrectAnswers
                  });
                  this.isGameActive = true;
                  this.getCurrentRegion();
                  this.rightAnswers = this.rightAnswers + 1;

                  // fly back
                  if(this.zoomBack){
                    this.map.flyTo({
                      center: this.baseCenter,
                      zoom: this.baseZoom
                    });
                  }

                  
                }, 2000);
              } else {
                
                this.geojson.features.forEach((feature, index) => {
                  if (feature.properties.name === this.regionToGuess || feature.properties['name:en'] === this.regionToGuess || feature.properties['name:ru'] === this.regionToGuess) {
                    // go to center of polygon

                    // move polygon to wrong answers

                    this.isLastAnswerCorrect = false;
                    this.geojsonWrongAnswers.push(feature);
                    this.map.getSource('rf-regions-wrong').setData({
                      'type': 'FeatureCollection',
                      'features': this.geojsonWrongAnswers
                    });
                    

                    this.map.flyTo({
                      center: turf.centerOfMass(feature).geometry.coordinates,
                      zoom: this.zoomZoom
                    });

                    // wait for 5 seconds
                    setTimeout(() => {

                      this.geojson.features.splice(index, 1);
                      this.geojsonWrongAnswers = [];
                      // update map source
                      this.map.getSource('rf-regions').setData(this.geojson);
                      this.map.getSource('rf-regions-wrong').setData({
                        'type': 'FeatureCollection',
                        'features': this.geojsonWrongAnswers
                      });
                      this.getCurrentRegion();
                      this.isGameActive = true;

                      // fly back
                      if(this.zoomBack){
                        this.map.flyTo({
                          center: this.baseCenter,
                          zoom: this.baseZoom
                        });
                      }

                    }, 2000);

                  }
                });
              }

            }

          }
        });
      });

      this.map.on('load', ()=> {
      this.map.addSource('rf-regions', {
        'type': 'geojson',
        'data': this.geojson
      })
      this.map.addSource('rf-regions-wrong', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': this.geojsonWrongAnswers
        }
      })
      this.map.addSource('rf-regions-correct', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': this.geojsonCorrectAnswers
        }
      })
      this.map.addLayer({
        'id': 'rf',
        'type': 'fill',
        'source': 'rf-regions',
        'layout': {},
        'paint': {
          'fill-color': '#088',
          'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0.5
          ],
          'fill-outline-color': '#000'
        }
      });
      this.map.on('mousemove', 'rf', (e) => {
        if (e.features.length > 0) {
          if (this.hoveredPolygonId !== null) {
            this.map.setFeatureState(
              { source: 'rf-regions', id: this.hoveredPolygonId },
              { hover: false }
            );
          }
          this.hoveredPolygonId = e.features[0].id;
          console.log(e.features[0].properties.name)
          this.map.setFeatureState(
            { source: 'rf-regions', id: this.hoveredPolygonId },
            { hover: true }
          );
        }
      });
      this.map.on('mouseleave', 'state-fills', () => {
        if (this.hoveredPolygonId !== null) {
          this.map.setFeatureState(
            { source: 'states', id: this.hoveredPolygonId },
            { hover: false }
          );
        }
        this.hoveredPolygonId = null;
      });
      this.map.addLayer({
        'id': 'rf-red',
        'type': 'fill',
        'source': 'rf-regions-wrong',
        'layout': {},
        'paint': {
          'fill-color': '#FF0000',
          'fill-opacity': 0.8,
          'fill-outline-color': '#000'
        }
      });
      this.map.addLayer({
        'id': 'rf-green',
        'type': 'fill',
        'source': 'rf-regions-correct',
        'layout': {},
        'paint': {
          'fill-color': '#7CFC00',
          'fill-opacity': 0.8,
          'fill-outline-color': '#000'
        }
      });
      this.map.addLayer({
        'id': 'rf-borders',
        'type': 'line',
        'source': 'rf-regions',
        'layout': {},
        'paint': {
        'line-color': '#627BC1',
        'line-width': 2
        }
      });
      this.getCurrentRegion();
    });
    },
    addScore(scoreToAppend){
      var score = localStorage.getItem('score');
      if(score){
        score = parseInt(score);
        score = score + scoreToAppend;
        localStorage.setItem('score', score);
      } else {
        localStorage.setItem('score', scoreToAppend);
      }
    }
}
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
  font-family: 'Nunito', sans-serif;
}
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.hud{
  background-color: #fff;
  text-align: center;
  z-index: 1;
  border-radius: 0px 0px 16px 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.container-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
#map { 
  width: 100%; 
  height: 100%;
  position: absolute;
}
</style>
