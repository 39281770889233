import { createApp } from 'vue'
import App from './App.vue'
import MenuComponent from './MenuComponent.vue'
import PlayComponent from './PlayComponent.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/', component: MenuComponent, name: 'menu' },
    { path: '/play/:gameString/:level', component: PlayComponent, name: 'play', props: true},
  ]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: "active",
    routes,
  })

let app = createApp(App)

app.use(router).mount('#app')
