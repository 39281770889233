<template>
  <div class="topoBg">
                <div class="container">
                <div class="row">
                    <div class="col-md-6 mx-auto">
                      <div class="row">
                        <div class="col-6">
                          <h2 v-if="this.lang == 'ru'"></h2>
                          <h2 v-if="this.lang == 'en'"></h2>
                        </div>
                        <div class="col-6" style="text-align: end;">
                          <span v-if="this.lang == 'ru'">ваши очки</span>
                          <span v-if="this.lang == 'en'">points</span>
                          <br>
                          <span class='score'>{{ this.score }}</span><br>
                        </div>
                      </div>

                    </div> 
                </div>
                </div>
            </div>
            <br>
            <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">

                    <h3 v-if="this.lang == 'ru'">Страны Мира</h3>
                    <h3 v-if="this.lang == 'en'">Countries of the World</h3>
                    <div class="horizontalCards">
                        <router-link to="/play/world/1">
                          <div class="infoCard"
                            style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/earth.png&quot;);">
                            <small class="infoCardRate" v-if="this.lang == 'ru'">Простой</small>
                            <small class="infoCardRate" v-if="this.lang == 'en'">Easy</small>
                            <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 10</small>
                            <small class="infoCardText" v-if="this.lang == 'en'">Random 10</small>
                          </div>
                        </router-link>

                        <router-link to="/play/world/2">
                          <div class="infoCard"
                            style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/earth.png&quot;);">
                            <small class="infoCardRate" v-if="this.lang == 'ru'">Сложный</small>
                            <small class="infoCardRate" v-if="this.lang == 'en'">Hard</small>
                            <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 20</small>
                            <small class="infoCardText" v-if="this.lang == 'en'">Random 20</small>
                          </div>
                        </router-link>

                        <router-link to="/play/world/3">
                          <div class="infoCard"
                            style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/earth.png&quot;);">
                            <small class="infoCardRate" v-if="this.lang == 'ru'">Хардкор</small>
                            <small class="infoCardRate" v-if="this.lang == 'en'">Hardcore</small>
                            <small class="infoCardText" v-if="this.lang == 'ru'">Все</small>
                            <small class="infoCardText" v-if="this.lang == 'en'">All</small>
                          </div>
                        </router-link>
                    </div>

                    <h3 v-if="this.lang == 'ru'">Регионы России</h3>
                    <h3 v-if="this.lang == 'en'">Russia Regions</h3>

                    <div class="horizontalCards">

                        <span>
                            <router-link to="/play/russia/1">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/ru.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Простой</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Easy</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 10</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 10</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/russia/2">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/ru.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Сложный</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Hard</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 25</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 25</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/russia/3">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/ru.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Очень сложный</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Very Hard</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 50</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 50</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/russia/4">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/ru.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Хардкор</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Hardcore</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Все</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">All</small>
                              </div>
                            </router-link>
                        </span>

                    </div>

                    <h3 v-if="this.lang == 'ru'">Районы Москвы</h3>
                    <h3 v-if="this.lang == 'en'">Districts of Moscow</h3>

                    <div class="horizontalCards">

                        <span>
                            <router-link to="/play/moscow/1">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/moscow.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Простой</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Easy</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 10</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 10</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/moscow/2">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/moscow.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Сложный</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Hard</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 25</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 25</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/moscow/3">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/moscow.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Очень сложный</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Very Hard</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Случайные 50</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">Random 50</small>
                              </div>
                            </router-link>
                        </span>

                        <span>
                            <router-link to="/play/moscow/4">
                            <div class="infoCard"
                                style="background-image: linear-gradient(0deg, rgb(2, 0, 36) 10%, rgba(0, 0, 0, 0) 100%), url(&quot;https://quiz.drusha.me/flags/moscow.png&quot;);">
                                <small class="infoCardRate" v-if="this.lang == 'ru'">Хардкор</small>
                                <small class="infoCardRate" v-if="this.lang == 'en'">Hardcore</small>
                                <small class="infoCardText" v-if="this.lang == 'ru'">Все</small>
                                <small class="infoCardText" v-if="this.lang == 'en'">All</small>
                              </div>
                            </router-link>
                        </span>

                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6 mx-auto">
                <br>
                <div class="row">
                    <div class="col-6">
                      language / язык
                    </div>
                    <div class="col-6">
                      <select class="form-select form-select-sm" v-model="this.lang">
                        <option value="ru">Русский</option>
                        <option value="en">English</option>
                      </select>
                    </div>
                </div>
              </div>
            </div>
        </div>
</template>

<script>

export default {

  data() {
    return {
      score: 0,
      lang: 'ru'
    }
  },
  watch: {
    lang: function (val) {
      localStorage.setItem('lang', val);
    }
  },
  mounted() {

    let language = localStorage.getItem('lang');
    if (language == null) {
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang == 'ru-RU' || userLang == 'ru') {
        this.lang = 'ru';
      } else {
        this.lang = 'en';
      }
      localStorage.setItem('lang', this.lang);
    } else {
      this.lang = localStorage.getItem('lang'); 
    }



    document.title = "borderquiz.com"
    this.score = localStorage.getItem('score');
    //console.log(this.score);
    if (this.score == null) {
      localStorage.setItem('score', 0);
    }
  }

}
</script>

<style>
  body {
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url("https://quiz.drusha.me/topography.png");
    transition: background-color 0.5s ease-in-out;
  }

  body:hover {
    background-color: white;
  }
  .topoBg{
      /*background-image: url("http://localhost:8080/topography.png");
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;*/
  }
  .infoCard{
      color: white;
      height: 7rem;
      width: 9rem;
      position: relative;
      border-radius: 16px;
      margin: 0.3rem;
      display: inline-block;
      background-size: cover;
      background-position: center;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      transition: transform .2s;
      overflow: hidden;
      text-overflow: ellipsis;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
        cursor: pointer;
    }
    
    .infoCard:hover{
      transform: scale(0.95);
    }
    .infoCardText{
      position: absolute;
      padding: 0.5rem;
      bottom: 0;
      left: 0;
      white-space: normal;
    }
    .infoCardRate{
      position: absolute;
      padding: 0.5rem;
      top: 0;
      right: 0;
      white-space: normal;
    }
    .horizontalCards{
      overflow-x: scroll;
      white-space: nowrap;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
    }
    .horizontalCards::-webkit-scrollbar {
      display: none;
    }
    .cardLink{
      color: white;
    }
    .score{
      font-family: 'Press Start 2P', cursive;
    }
</style>